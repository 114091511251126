import React from 'react';
import './bookmark.css';
import {Dropdown} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {AccountService} from "../../../../services/account.service";
import {BsStar, BsStarFill} from "react-icons/bs";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from "../../../../redux/actions/loader"
import { BsFillFolderFill , BsFolder2Open } from "react-icons/bs";

class Bookmark extends React.Component {
  constructor(props) {
    super(props);
    this.target = React.createRef(null);
    this.props.START_LOADING();
    this.state = {
      bookmarkList: [],
      bm_folders : {},
        bookmarkNameInput: '',
        isShow: false,
        pageSelected: null,
        showBookmark: false,
        isBookmarked: false,
        showFolderInput : false,
        url: '',
        showBookmarkTooltip: false,
        openFolders: {},
        dropdownVisible: false,
        editFolderDropdownVisible : false,
        new_folder : '',
        folderIsRenamed : {},
        selectedFolderIdInput : '',
        editBookmarkDropdownVisible : {},
        bookmarkIsRenamed : {},
        createBookmarkError : false,
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    const bookmarkList = await AccountService.getBookmark()
    const bm_folders = await AccountService.listBookmarkFolder()
    if (bookmarkList && bm_folders) {
      const folders =  bm_folders.data
      this.setState({
        bm_folders :folders,
        bookmarkNameInput: this.getCurrentWindowUrl(),
        url: this.getCurrentWindowUrl(),
        bookmarkList: bookmarkList.data,
        isBookmarked : this.findUrlInBookmarks(bookmarkList.data,this.getCurrentWindowUrl())
      });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
    this.unlisten = this.props.history.listen((location) => {
        this.handleIconChange(this.state.bookmarkList)
        this.setState({
            name: this.getCurrentWindowUrl()
        })
    });
    this.props.STOP_LOADING();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.unlisten();
  }

  getCurrentWindowUrl =() => {
    let url = window.location.href.trim("");
    return url.replace(/\/+$/, '');
  }
  

  //change URL
  handleIconChange = (bookmarkList) => {
    const status = this.findUrlInBookmarks(bookmarkList,this.getCurrentWindowUrl())
    this.setState({
      isBookmarked: status
    })
  }

  handleToggleBookmark =() => {
    const bookmark = !this.state.showBookmark
    this.setState({showBookmark: bookmark})
  }

  handleClickOutside = (event) => {
    // toggle bookmark window
    const star = document.getElementById('star')
    const bookmarkBox = document.getElementById('bookmark-out')
    if (
        bookmarkBox &&
        !bookmarkBox.contains(event.target)
        && !star.contains(event.target)
    ) {
      this.setState({showBookmark: false});
    }

    // close rename input if click outside
    const renameInput = document.querySelector(".rename-input");
    if (renameInput && !renameInput.contains(event.target)) {
      this.setState({folderIsRenamed : {}})
    }

    // close rename bookmark input if click outside
    const renameBookmarkInput = document.querySelector(".rename-bookmark-input");
    if (renameBookmarkInput && !renameBookmarkInput.contains(event.target)) {
      this.setState({bookmarkIsRenamed : {}})
    }

    // close new folder input if click outside
    const createFolderDiv = document.querySelector(".create-folder-div");
    if (createFolderDiv && !createFolderDiv.contains(event.target)) {
      this.setState({showFolderInput : false})
    }

  };

  toogleFolderInput() {
    this.setState((prevState) => ({
        showFolderInput:!prevState.showFolderInput,
    }));
  }

  handleBookmarkTooltip() {
    this.setState({
      showBookmarkTooltip: true
    })
  }

  closeBookmarkTooltip() {
    this.setState({
      showBookmarkTooltip: false
    })
  }

  toggleFolder = (folderId) => {
    this.setState((prevState) => ({
      openFolders: {
        ...prevState.openFolders,
        [folderId]: !prevState.openFolders[folderId],
      },
    }));
  };

  showFolderListDropdown = () => {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  };

  // api function
  createFolder = async () => {
    const folderName = this.state.new_folder
    const res = await AccountService.createBookmarkFolder(folderName);
    if (res.status === 200) {
      const folder = res.data
      const bm_folders =  [...this.state.bm_folders,folder]
      const prev_bookmarkList = this.state.bookmarkList
      const bookmarkList = {...prev_bookmarkList, [folder.id] : { folderName : folder.folderName , bookmarkList : []}}
      this.setState({
        bm_folders,
        bookmarkList,
        showFolderInput : false,
        new_folder : ''
      })
    }  
  }
  
  renameFolder = async (folderId,folderNewName) => {
    if (folderNewName) {
      const res = await AccountService.renameBookmarkFolder(folderId.toString(),folderNewName);
      if (res.status === 200) {
        let bm_folders = this.state.bm_folders
          bm_folders = bm_folders.map(folder => 
            folder.id === folderId ? { ...folder, folderName: folderNewName } : folder
          );
        this.setState({bm_folders})
      }  
    } 
    this.setState({ folderIsRenamed: {} });       
  }

  moveBookmark = async (bookmark,newFolderId) => {
    const res = await AccountService.move_bookmark(bookmark.id.toString(),newFolderId.toString());
    if (res.data.is_success) {
      const prev_bookmarkList = this.state.bookmarkList
      let bookmarkList = this.removeBookmarkFromBookmarkListById(prev_bookmarkList,bookmark.id)
      bookmark = this.addBookmarkToBookmarkList(prev_bookmarkList,bookmark,newFolderId)
      this.setState({bookmarkList});
    }  
  }

  deleteBookmarks = async (id) => {
    if (id) {
      const bookmark_to_be_deleted = [id]
      await AccountService.deleteBookmarkByIds(bookmark_to_be_deleted);
      const bookmarkList = this.removeBookmarkFromBookmarkListById(this.state.bookmarkList,id)
      this.handleIconChange(bookmarkList)
      this.setState({bookmarkList})
    } 
  };

  createBookmark = async () => {
    const folderId = this.state.selectedFolderIdInput.toString()
    const bookmarkNameInput = this.state.bookmarkNameInput
    if (folderId && bookmarkNameInput) {
      this.setState({createBookmarkError: false})
      const param = {
        name: bookmarkNameInput,
        url: this.getCurrentWindowUrl(),
        folderId
      };
      const res = await AccountService.addBookmark(param);
      if (res.status ===200) { 
        const newBookmark = res.data
        const prev_bookmarkList = this.state.bookmarkList
        const bookmarkList = this.addBookmarkToBookmarkList(prev_bookmarkList, newBookmark, newBookmark.folderId)
        const prev_openFolders = this.state.openFolders
        const openFolders = {
          ...prev_openFolders,
          [newBookmark.folderId]: true
        }
        this.handleIconChange(bookmarkList)
        this.setState({bookmarkList,openFolders})
      }
    } else {
      this.setState({createBookmarkError: true})
    }
  }

  deleteFolder = async (folder_id) => {
    const res = await AccountService.deleteBookmarkFolder(folder_id.toString());
    if (res.data.is_success) {
      const prev_bm_folders = this.state.bm_folders
      const prev_bookmarkList = this.state.bookmarkList
      const bm_folders = this.removeFolderFromFolderList(prev_bm_folders,folder_id)
      const bookmarkList = this.removeFolderFromBookmarkList(prev_bookmarkList,folder_id)
      this.handleIconChange(bookmarkList)
      this.setState({
        bm_folders,
        bookmarkList
      })
    }     
  }

  renameBookmark = async (bookmarkId,newBookmarkName) => {
    if (newBookmarkName) {
      const res = await AccountService.renameBookmarkById(bookmarkId.toString(),newBookmarkName);
      if (res.status === 200) {
        const bookmarkList = this.renameBookmarkinBookmarkList(this.state.bookmarkList,bookmarkId,newBookmarkName)
        this.setState({bookmarkList})
      }   
    } 
    this.setState({bookmarkIsRenamed:{}}) 
  }

  removeCurrentUrlFromBookmark = async () => {
    let isBookmarked = this.state.isBookmarked
    if (isBookmarked) {
      const res = await AccountService.deleteBookmarksByUrl(this.state.url);
      if (res.data.is_success) {
        isBookmarked = false
        const bookmarkList = this.removeBookmarksByUrl(this.state.bookmarkList,this.state.url)
        this.setState({bookmarkList,isBookmarked})
      } 
    }  
  }

// helper functions
  removeFolderFromBookmarkList = (data, folderId) => {
    const updatedData = { ...data };
    if (updatedData[folderId]) {
      delete updatedData[folderId];
    }
    return updatedData;
  }

  removeFolderFromFolderList = (folders, folderId) => {
    const updatedFolders = folders.filter(folder => folder.id !== Number(folderId));
    return updatedFolders;
  }

  addBookmarkToBookmarkList = (data, bookmark, folder_id) => {
    const updatedData = { ...data }; 
    this.removeBookmarkFromBookmarkListById(updatedData, bookmark.id) 
    if (updatedData[folder_id]) {
      updatedData[folder_id].bookmark_list = [...updatedData[folder_id].bookmark_list, bookmark];
    } 
    return updatedData;
  }

  removeBookmarkFromBookmarkListById(data, bookmark_id) {
    const updatedData = { ...data }; 
    for (const folderId in updatedData) {
      const folder = updatedData[folderId];
      const bookmarkIndex = folder.bookmark_list.findIndex(bookmark => bookmark.id === bookmark_id);

      if (bookmarkIndex !== -1) {
        folder.bookmark_list = [
          ...folder.bookmark_list.slice(0, bookmarkIndex),
          ...folder.bookmark_list.slice(bookmarkIndex + 1)
        ];
        return updatedData;
      }
    }
    return updatedData; 
  }

  findUrlInBookmarks = (bookmark_list, current_url) => {
      if (typeof bookmark_list !== 'object' || bookmark_list === null) {
        console.error('Invalid bookmark_list: not an object');
        return false;
      }

      for (const folder of Object.values(bookmark_list)) {
        const found = folder.bookmark_list.some(
          (bookmark) => bookmark.url === current_url
        );
        if (found) {
          return true; 
        }
      }
      return false;
    };

    renameBookmarkinBookmarkList = (data, id, newName) => {
      for (const folderId in data) {
        const folder = data[folderId];
        const bookmarkList = folder.bookmark_list;

        for (let i = 0; i < bookmarkList.length; i++) {
          if (bookmarkList[i].id === id) {
            bookmarkList[i].name = newName;
            return data;
          }
        }
      }
      
      return data;
    }

     removeBookmarksByUrl = (data, targetUrl) => {
      for (const folderId in data) {
        if (data[folderId].bookmark_list) {
          data[folderId].bookmark_list = data[folderId].bookmark_list.filter(bookmark => bookmark.url !== targetUrl);
        }
      }
      return data;
    }

  render() {
    const {
      showBookmark,
      showBookmarkTooltip,
      bookmarkList,
      bookmarkNameInput,
      bm_folders,
      showFolderInput,
      new_folder,
      editFolderDropdownVisible,
      folderIsRenamed,
      selectedFolderIdInput,
      editBookmarkDropdownVisible,
      bookmarkIsRenamed,
      isBookmarked,
      createBookmarkError
    } = this.state;

    return (
      <>
        <div className="star" id='star' onClick={() => this.handleToggleBookmark()} onMouseOver={() => this.handleBookmarkTooltip()} onMouseLeave={() => this.closeBookmarkTooltip()}>
            {this.state.isBookmarked ? <BsStarFill/>:<BsStar/>}   
            {showBookmarkTooltip && <div id='bm-tooltip'>Bookmark</div>}
        </div>
        {showBookmark &&
        <div className='bookmark-out' id='bookmark-out'>
          <div className='bookmark-box'>
            <div className='bm-list-title'><b>Create Bookmarks</b></div>
              <div className='input-bm-div'>
                <label>Name</label>
                <div className='input-bm-item'>
                  <input
                      type='text'
                      onClick={(e) => e.target.select()}
                      value={bookmarkNameInput}
                      id='bm-name'
                      onChange={(e) => this.setState({
                        bookmarkNameInput: e.currentTarget.value
                      })}
                      onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              this.showFolderListDropdown()
                          }
                        }
                      }
                      >
                    </input>
                    <select value={selectedFolderIdInput} onChange={(event)=> this.setState({selectedFolderIdInput: event.target.value })}>
                      <option key="-1" value="" disabled>Select a folder</option>
                      {Array.from(bm_folders).length>0 && Array.from(bm_folders).map((item, index) => (
                        <option key={index} value={item["id"]}>{item["folderName"]}</option>
                      ))}
                    </select>
                    {createBookmarkError && <p>Bookmark name and folder are requied.</p>}
                    <div className='d-flex gap-2 flex-wrap'> 
                      <Button variant="dark" className="bookmark-button" onClick={this.createBookmark}>Create Bookmark</Button>
                      {isBookmarked && <Button variant="dark" className="bookmark-button" onClick={this.removeCurrentUrlFromBookmark}>Remove</Button>}
                      <Button 
                        variant="dark"
                        className="bookmark-button"  
                        onClick={() => { this.toogleFolderInput()}}>
                      Create Folder
                      </Button>
                    </div>
                  {showFolderInput && 
                  <div className='create-folder-div'>
                      <input
                      type='text'
                      onClick={(e) => e.target.select()}
                      value={new_folder}
                      id='folder-name'
                      className="mr-2 mb-2"
                      placeholder='Bookmark Folder'
                      onChange={(e) => this.setState({
                          new_folder: e.currentTarget.value
                      })}
                      onKeyDown={(event) => {
                          if (event.key === "Enter") {
                              this.createFolder()
                          }
                      }
                      }></input>
                      <Button variant='outline-light' className="bookmark-button" onClick={() => {this.createFolder()}}>OK</Button>
                  </div>
                  }
                </div>
              </div>

        
                <div className="bookmark-body-header">
                    <div className='bm-list-title'>
                        <div><b>Bookmark List</b></div>
                    </div>
                </div>
                <div className="bm-list">
                {/* Bookmark Folder */}
                  {Array.from(bm_folders).map((item, index) => (
                  <div
                    key={index}
                    className='folder-div'
                    data-folderId={item.id}
                    onDrop={(e) => {
                      e.preventDefault();
                      const bookmarkData = e.dataTransfer.getData("bookmark");
                      const sourceFolderId = e.dataTransfer.getData("folderId");
                      const  bookmark = JSON.parse(bookmarkData)
                      if (sourceFolderId !== item.id.toString()) {
                        this.moveBookmark(bookmark, item.id);
                      }
                    }}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <div className='d-flex justify-between pr-2'>
                      {(folderIsRenamed[item.id] || folderIsRenamed[item.id] ==='' ) ? 
                        <input
                          type='text'
                          value={folderIsRenamed[item.id]}
                          className='rename-input'
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            this.setState({ folderIsRenamed: { [item.id]: value } });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const new_name = e.currentTarget.value
                              this.renameFolder(item.id, new_name);                       
                            }
                          }}
                        />
                      : 
                        <button
                          className="folder-name d-flex align-items-center mb-2"
                          onClick={() => this.toggleFolder(item.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {(this.state.openFolders[item.id]) ? <BsFolder2Open className='icon'/> : <BsFillFolderFill className='icon'/>}
                          <span className="font-bold mx-1">{item.folderName}</span>
                        </button>
                      }

                      <Dropdown show={editFolderDropdownVisible[item.id]} drop="start"
                        onToggle={(isOpen) => this.setState({ editFolderDropdownVisible: { ...editFolderDropdownVisible, [item.id]: isOpen } })}>
                        <Dropdown.Toggle variant='transparent'>⋮</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.setState({ folderIsRenamed: { [item.id]: item.folderName } })}>Rename</Dropdown.Item>
                          <Dropdown.Item onClick={() => this.deleteFolder(item.id)}>Delete Folder</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    {/* Bookmarks of each folder */}
                    {this.state.openFolders[item.id] && bookmarkList[item.id]['bookmark_list'].length > 0 && (
                      <ul>
                        {bookmarkList[item.id]['bookmark_list'].map((path, index) => (
                          <div
                            className="bm-item-div"
                            key={index}
                            draggable={!bookmarkIsRenamed[path.id]}
                            onDragStart={(e) => {
                              e.dataTransfer.setData("bookmark", JSON.stringify(path));
                              e.dataTransfer.setData("folderId", item.id);
                            }}
                          >
                           

                            {(bookmarkIsRenamed[path.id] || bookmarkIsRenamed[path.id] ==='') ? 
                              <input
                                type='text'
                                value={bookmarkIsRenamed[path.id]}
                                className='rename-bookmark-input'
                                onChange={(e) => {
                                  let value = e.currentTarget.value;
                                  this.setState({ bookmarkIsRenamed: { [path.id]: value } });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.renameBookmark(path.id, e.currentTarget.value);
                                  }
                                }}
                              />
                            : 
                              <a
                                className="bookmark-list-item"
                                href={path.url}
                                data-bookmarkId={path.id}
                                data-folderId={item.id}
                              >
                                {path.name}
                              </a>
                            }

                             <Dropdown show={editBookmarkDropdownVisible[path.id]} drop="start" data={path.id}
                              onToggle={(isOpen) => this.setState({ editBookmarkDropdownVisible: { ...editBookmarkDropdownVisible, [path.id]: isOpen } })}>
                              <Dropdown.Toggle variant='transparent'>⋮</Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.setState({ bookmarkIsRenamed: { [path.id]: path.name } })}>Rename</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.deleteBookmarks(path.id)}>Delete Bookmark</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
                </div>
          </div>
        </div>
        }
      </>
    );
  }
}


export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(Bookmark));