import React from 'react';
import './verse-card.css';
import 'font-awesome/css/font-awesome.min.css';
import {replaceWithHighlight} from '../../../../utils.js';


export class VerseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            content: null,
        };
    }

    render() {
        const {
            verse_id_string,
            verse_raw_text,
            doc_name,
            chapter_number,
            handleOnclickReadMore,
            handleOnclickReadIcon,
            keyword
        } = this.props;
        return (
            <div className="verse-card verse-font">
                <div className="text-center">– {verse_id_string} –</div>
                <div>{replaceWithHighlight(verse_raw_text, keyword)}</div>
                <div className="readmore-box">

                    <div
                        className="readmore button"
                        onClick={handleOnclickReadMore}
                    >
                        Read More of {doc_name} {chapter_number}
                    </div>

                    <div className="volume-up">
                        <i
                            className="fa fa-volume-up volume-up-icon button"
                            onClick={() => {
                                handleOnclickReadIcon(verse_raw_text, verse_id_string)
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    }
}
